

import { mapActions } from "vuex";
// import * as types from "../../vuex/types";
import axios from 'axios';
export default {
  name: "EquipmentFinancingLandingPageFilter1",
  data() {
    return {
      qualified: true,
      current_step: 1,
      max_step: 10,
      credit_score: '',
      capital_need: null,
      capital_need_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: '$5,000 - $10,000', text: '$5,000 - $10,000' },
        { value: '$10,000 – $20,000', text: '$10,000 – $20,000' },
        { value: '$20,000 – $50,000', text: '$20,000 – $50,000' },
        { value: '$50,000 – $100,000', text: '$50,000 – $100,000' },
        { value: '$100,000 - $250,000', text: '$100,000 - $250,000' },
        { value: '$250,000 - $500,000', text: '$250,000 - $500,000' },
        { value: '$500,000 - $1,000,000', text: '$500,000 - $1,000,000' },
        { value: '$1,000,000+', text: '$1,000,000+' }
      ],
      when_need: null,
      when_need_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Within a week', text: 'Within a week' },
        { value: 'Within 2 weeks', text: 'Within 2 weeks' },
        { value: 'Within a month', text: 'Within a month'},
        { value: 'Unsure', text: 'Unsure'}
      ],
      business_year: null,
      business_year_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Startup (less than 2 years)', text: 'Startup (less than 2 years)' },
        { value: '2 – 4 years', text: '2 – 4 years' },
        { value: '5 – 9 years', text: '5 – 9 years'},
        { value: '10+ years', text: '10+ years'}
      ],
      annual_revenue: null,
      annual_revenue_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Below $100,000', text: 'Below $100,000' },
        { value: '$100,000 – $200,000', text: '$100,000 – $200,000' },
        { value: '$200,000 – $300,000', text: '$200,000 – $300,000' },
        { value: '$300,000 – $500,000', text: '$300,000 – $500,000' },
        { value: '$500,000 – $1,000,000', text: '$500,000 – $1,000,000' },
        { value: '$1,000,000 – $3,000,000', text: '$1,000,000 – $3,000,000' },
        { value: '$3,000,000 – $5,000,000', text: '$3,000,000 – $5,000,000' },
        { value: '$5,000,000+', text: '$5,000,000+' }
      ],
      why_need: null,
      why_need_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Equipment Purchase', text: 'Equipment Purchase' },
        { value: 'Accounts receivable finance', text: 'Accounts receivable finance' },
        { value: 'Vehicle purchase', text: 'Vehicle purchase' },
        { value: 'Meet payroll', text: 'Meet payroll' },
        { value: 'Inventory', text: 'Inventory' },
        { value: 'Commercial real estate', text: 'Commercial real estate' },
        { value: 'Scaling or expansion', text: 'Scaling or expansion' },
        { value: 'Marketing', text: 'Marketing' },
        { value: 'Location remodel', text: 'Location remodel' },
        { value: 'Debt refinance', text: 'Debt refinance' },
        { value: 'Buy a business', text: 'Buy a business' },
        { value: 'Start a business', text: 'Start a business' },
        { value: 'Other', text: 'Other' }
      ],
      business_name: '',
      first_name: '',
      last_name: '',
      phone: null,
      email: null
    };
  },
  computed: {
    progress: function() {
      return Math.round(100 / this.max_step) * this.current_step;
    },
    emailState: function() {
      if(this.email == null) return false;
      var filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(filter.test(this.email)) {
        return true;
      }
      return false;
    },
    phoneState: function() {
      if(this.phone == null) return false;
      var current = this.phone.replace(/\D/g,'');
      if(current.length < 10) return false;
      return true;
    }
  },
  methods: {
    ...mapActions({
      // setFailMessage: types.SET_FAIL_MESSAGE
    }),
    formatter: function(input) {
      // Strip all characters from the input except digits
      input = input.replace(/\D/g,'');
      
      // Trim the remaining input to ten characters, to preserve phone number format
      input = input.substring(0,10);

      // Based upon the length of the string, we add formatting as necessary
      var size = input.length;
      if(size == 0){
              input = input;
      }else if(size < 4){
              input = '('+input;
      }else if(size < 7){
              input = '('+input.substring(0,3)+') '+input.substring(3,6);
      }else{
              input = '('+input.substring(0,3)+') '+input.substring(3,6)+'-'+input.substring(6,10);
      }
      return input; 
    },
    onClickApply: function() {
      if(this.capital_need == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select the capital amount your business needs.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickCredit: function(score) {
      if(score == 'Poor(620 or less)') {
        this.qualified = false;
        this.current_step = 10;
        return;
      }
      this.credit_score = score;
      this.current_step++;
    },
    onClickYear: function() {
      if(this.business_year == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select how many years you have been in business.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      if(this.business_year == 'Startup (less than 2 years)') {
        this.qualified = false;
        this.current_step = 10;
        return;
      }
      this.current_step++;
    },
    onClickRevenue: function() {
      if(this.annual_revenue == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select your annual revenue.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      if(this.annual_revenue == 'Below $100,000' || this.annual_revenue == '$100,000 – $200,000') {
        this.qualified = false;
        this.current_step = 10;
        return;
      }
      this.current_step++;
    },
    onClickWhy: function() {
      if(this.why_need == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select what you need the financing for.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickWhen: function() {
      if(this.when_need == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select when you need the financing.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickBusinessName: function() {
      if(this.business_name == '') {
        return this.$fire({
          // title: "Title",
          text: "Please input your business name.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickName: function() {
      if(this.first_name == '' || this.last_name== '') {
        return this.$fire({
          // title: "Title",
          text: "Please input your first and last name.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickSubmit: function() {
      if(this.phoneState == false || this.emailState == false) {
        return this.$fire({
          // title: "Title",
          text: "Please input valid phone number and email.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      var leadData = {
        loan_amount: this.capital_need,
        credit_score: this.credit_score,
        year_in_business: this.business_year,
        annual_revenue: this.annual_revenue,
        use_of_finance: this.why_need,
        loan_need_time: this.when_need,
        business_name: this.business_name,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone,
        email_address: this.email,
        campaign: this.$route.query.campaign
      };
      //slack notice webhook
      axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
                  .post('https://hooks.zapier.com/hooks/catch/11204908/beg2zkp/',leadData);
      //hubspot new lead webhook
      axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
                  .post('https://hooks.zapier.com/hooks/catch/11204908/begzrid/',leadData);
      //send welcome email to FB new lead
      axios.post("https://api.amcredit.com/apply/sendTransactionalEmail", {customerEmail: this.email, firstName: this.first_name, emailTemplate: 'fb-new-lead-welcome-transactional', emailSubject: 'Thank you for choosing American Credit for your equipment financing' })
      .then(res => console.log(res))
      .catch(err => console.log(err.message));
      this.current_step++;
    }
  }
};
